import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  manageProjectsHeading: any;
  constructor() { }

  manageCaregiversHeading = new BehaviorSubject(false);
  manageClientHeading = new BehaviorSubject(false);
  manageServicesHeading = new BehaviorSubject(false);
  manageTutorialHeading = new BehaviorSubject(false);
  manageConvenienceHeading = new BehaviorSubject(false);
  manageDiseasesHeading = new BehaviorSubject(false);
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading = new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  manageInterestdHeading = new BehaviorSubject(false);
  manageLocalHeading = new BehaviorSubject(false);
  broadcastNotificationHeading = new BehaviorSubject(false);
  notificationHeading = new BehaviorSubject(false);
  manageOrganisationHeading = new BehaviorSubject(false);
  manageProjectHeading = new BehaviorSubject(false);
  manageCategoriesHeading = new BehaviorSubject(false);
  manageOrganisationViewHeading = new BehaviorSubject(false);
  manageCouponHeading = new BehaviorSubject(false);
  manageContestHeading = new BehaviorSubject(false);





}
