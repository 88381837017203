import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/components/login/login.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  // {
  //   path: "manage-organisations",
  //   component: ,
  // },  

  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },



      {
        path: "analytics-dashboard",
        loadChildren: () =>
          import("../app/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          )
      },

      {
        path: "manage-organisations",
        loadChildren: () =>
          import("../app/views/manage-organisations/manage-organisations.module").then(
            (m) => m.ManageOrganisationsModule
          )
      },

      {
        path: "manage-projects",
        loadChildren: () =>
          import("../app/views/manage-projects/manage-projects.module").then(
            (m) => m.ManageProjectsModule
          )
      },

      {
        path: "manage-business",
        loadChildren: () =>
          import("../app/views/manage-category/manage-category.module").then(
            (m) => m.ManageBusinessModule
          )
      },

      {
        path: "manage-coupons",
        loadChildren: () =>
          import("../app/views/manage-coupon/manage-coupon.module").then(
            (m) => m.ManageCouponModule
          )
      },

      {
        path: "manage-contests",
        loadChildren: () =>
          import("../app/views/manage-contest/manage-contest.module").then(
            (m) => m.ManageContestsModule
          )
      },
    ],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
