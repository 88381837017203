<div class="sidebar">
  <div class="logo sidebarlogo"><img src="../../../assets/Palmy images/Rectangle.png"
      style="height: 75px;width: 75px;margin-left: 13px;margin-top: -5px;" alt="logo"></div>

  <div class="header-links">


    <a routerLink="/analytics-dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/reporting.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/sidebar/reporting.svg" alt="" height="24" width="24">
      </span>Reporting</a>

    <a routerLink="/manage-organisations" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
      </span>Manage Organizations</a>

    <a routerLink="/manage-projects" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
      </span>Manage Projects</a>

    <a routerLink="/manage-business" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/Manage Business.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/sidebar/Manage_Business_White.svg" alt="" height="24"
          width="24">
      </span>Manage Business</a>

    <a routerLink="/manage-coupons" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/sidebar/blackCoupon.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/sidebar/coupon.svg" alt="" height="24" width="24">
      </span>Manage Coupons</a>

    <a routerLink="/manage-contests" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Reporting.svg" alt="" height="24" width="24">
      </span>Manage Contests</a>

    <a routerLink="/edit-profile" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Chnage Password Black.svg" alt="" height="24"
          width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Chnage Password Black.svg" alt="" height="24"
          width="24">
      </span>Change Password </a>

    <a class="dropdown-item admin-dropdown" routerLinkActive="active" id="logout" (click)="openModal(template)">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/icons/SVGLOGO/Log Out Black.svg" alt="" height="24" width="24">
        <img class="white-icon" src="../../../assets/icons/SVGLOGO/Log Out Black.svg" alt="" height="24" width="24">
      </span>
      Logout</a>
  </div>
</div>


<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
    <p>Are you sure you <br>want to logout?</p>
    <div class="modal-btn d-flex justify-content-around">
      <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
      <button class="btn btn-blue" (click)="confirm()">Yes</button>
    </div>
  </div>

</ng-template>